<template>
  <div class="antialiased tracking-wide">
    <Navbar />
    <transition
      :duration="{ enter: 600, leave: 300 }"
      name="fade"
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view></router-view>
    </transition>
    <Cookie />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    Navbar: () => import("@/components/Theme/Navbar"),
    Cookie: () => import("@/components/Theme/Cookie"),
  },
  metaInfo() {
    return {
      title: "GOOD WAY Life Discovery",
      meta: [
        {
          name: "description",
          content:
            "GOOD WAY Life Discovery to wspólne odkrywanie największych wartości, dzięki którym Twoje życie nabierze sensu.",
        },
        {
          name: "keywords",
          content: "Rozwój, życie wewnętrzne, spokój, miłość, wartości",
        },
      ],
    };
  },
};
</script>

<style lang="scss" src="@/assets/css/app.scss"></style>
