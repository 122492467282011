import Vue from "vue";
import VueRouter from "vue-router";
import VueScrollTo from "vue-scrollto";
import VueAnalytics from "vue-analytics";
import VueYandexMetrika from "vue-yandex-metrika";

import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/diagnostyka",
    name: "Diagnostic",
    component: () => import("@/views/Diagnostic.vue"),
  },
  {
    path: "/inspiracja",
    name: "Inspiration",
    component: () => import("@/views/Inspiration.vue"),
  },
  {
    path: "/miejsce-odkrycia",
    name: "Event",
    component: () => import("@/views/Event.vue"),
  },
  {
    path: "/indywidualne-odkrycie",
    name: "Individual",
    component: () => import("@/views/Individual.vue"),
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/regulamin",
    name: "Statute",
    component: () => import("@/views/Statute.vue"),
  },
  {
    path: "/polityka-prywatnosci",
    name: "Privacy",
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: "/rodo",
    name: "Rodo",
    component: () => import("@/views/Rodo.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("@/views/Blog.vue"),
  },
  {
    path: "/blogsearch",
    name: "BlogSearchFirst",
    component: () => import("@/views/BlogSearchFirst.vue"),
  },
  {
    path: "/blogsearch/:search",
    name: "BlogSearch",
    component: () => import("@/views/BlogSearch.vue"),
  },
  {
    path: "/blogsearch/:search/:page",
    name: "BlogSearchPage",
    component: () => import("@/views/BlogSearchPage.vue"),
  },
  {
    path: "/blog-:page",
    name: "BlogPage",
    component: () => import("@/views/BlogPage.vue"),
  },
  {
    path: "/blog/:name",
    name: "BlogShow",
    component: () => import("@/views/BlogShow.vue"),
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/Error.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 });
        }, 500);
      });
    }
  },
});

router.afterEach((to) => {
  if (to.hash) {
    setTimeout(() => {
      VueScrollTo.scrollTo(to.hash);
    }, 500);
  }
});

const getGDPR = localStorage.getItem("GDPR:accepted");

Vue.use(VueAnalytics, {
  id: "UA-164143905-1",
  router,
  disabled: typeof getGDPR !== "undefined" && getGDPR === "true" ? false : true,
});

Vue.use(VueYandexMetrika, {
  id: 65259937,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "goodway",
  },
});

export default router;
