<template>
  <section class="w-full relative">
    <div class="absolute top-0 left-0 h-full w-full">
      <video
        autoplay
        muted
        playsinline
        @ended="moveTo"
        class="h-full w-full object-cover object-center"
        :poster="require('@/assets/img/trailer.jpg')"
      >
        <source
          :src="require('@/assets/media/trailer.mp4')"
          type="video/mp4"
          media="screen and (min-device-width:801px)"
        />
        <source
          :src="require('@/assets/media/trailermob.mp4')"
          type="video/mp4"
          media="screen and (max-device-width:800px)"
        />
      </video>
    </div>
    <div class="text-center absolute top-0 left-0 w-full z-10 flex items-center justify-center">
      <h4
        class="text-gray-200 lg:text-2xl text-base py-8 animate__animated animate__fadeIn animate__slow"
      >Good Way Trailer</h4>
    </div>
    <div
      class="text-gray-200 absolute bottom-0 right-0 w-full z-10 flex items-center justify-center p-8 animate__animated animate__fadeIn animate__slow"
    >
      <a href="#filozofia" v-scroll-to="'#filozofia'">
        <svg
          class="h-10 fill-current bounce my-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M13.418,7.859c0.271-0.268,0.709-0.268,0.978,0c0.27,0.268,0.272,0.701,0,0.969l-3.908,3.83
	c-0.27,0.268-0.707,0.268-0.979,0l-3.908-3.83c-0.27-0.267-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.978,0L10,11L13.418,7.859z
	"
          />
        </svg>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "Trailer",
  methods: {
    moveTo() {
      this.$scrollTo("#filozofia");
    }
  }
};
</script>
