import Vue from "vue";
import App from "./App";
import router from "./router";
import "./plugins/vue-scrollto";
import "./plugins/vue-animate-onscroll";
import "./plugins/vue-youtube";
import "./plugins/vue-meta";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
