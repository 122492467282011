<template>
  <section
    class="w-full bg-cover bg-center flex items-center justify-center relative"
    :style="
      `background-image: url('${require('@/assets/img/inspiration/7.jpg')}')`
    "
  >
    <div class="absolute top-0 w-full">
      <img src="@/assets/img/logo.svg" class="lg:h-32 h-24 mx-auto" alt="Goodway" />
    </div>
    <router-link
      class="z-20"
      to="/diagnostyka"
      v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
    >
      <button
        class="mx-auto pulse rounded-full w-24 h-24 uppercase text-gray-200 bg-secondary font-sans text-sm tracking-wider"
      >Odkryj</button>
    </router-link>
    <div
      class="text-center w-full lg:h-48 absolute bottom-0 left-0 bg-thirdry flex items-center justify-center"
    >
      <h4
        class="md:py-6 px-10 lg:text-3xl text-xl text-gray-200 font-sans text-center font-thin"
        v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
      >
        Odkryj stan, który sprawi, że
        <span class="font-extrabold">Twoje życie zaświeci na nowo</span>
        <span
          class="lg:h-10 h-6 lg:pl-6 pl-4 bg-cover bg-center"
          :style="
            `background-image: url('${require('@/assets/img/dot.svg')} ');`
          "
        ></span>
      </h4>
    </div>
    <div
      class="w-full absolute bottom-0 left-0 bg-right-bottom bg-cover md:hidden"
      :style="
        `background-image: url('${require('@/assets/img/ecg.png')} '); height: 25rem;`
      "
    ></div>
  </section>
</template>

<script>
export default {
  name: "Discover"
};
</script>
