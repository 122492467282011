<template>
  <div id="index" ref="index" class="leading-loose">
    <component
      v-bind:is="item.component"
      v-for="(item, index) in items"
      :key="index"
      :id="item.name"
      class="min-h-screen"
    ></component>
  </div>
</template>

<script>
import Trailer from "@/components/Home/Trailer";
import Logo from "@/components/Home/Logo";
import LogoSec from "@/components/Home/LogoSec";
import Discover from "@/components/Home/Discover";

export default {
  name: "Home",
  data() {
    return {
      items: [
        { component: "Trailer", name: "trailer" },
        { component: "Logo", name: "filozofia" },
        { component: "LogoSec", name: "filozofiasec" },
        { component: "Discover", name: "discover" },
      ],
    };
  },
  components: {
    Trailer,
    Logo,
    LogoSec,
    Discover,
  },
};
</script>
